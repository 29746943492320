import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, catchError, throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  tokenNeededApi: string[] = [
    "/cart/fetch",
    "/customer/info",
    "/cart/add",
    "/cart/product/remove",
    "/ord/order/save",
    "/cat/cart/update",
    "/ord/custom-order",
    // "/cat/custom-order/",
    "/customer/update",
    "/product/catalog-share/get/list"
  ]

  noTokenApi: string[] = [
    "/ord/n",
    "/ord/item/item-details/sms-link"
  ]

  constructor(
    private storage: StorageService,
  ) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let needToken = true;
    for (let i = 0; i < this.noTokenApi.length; i++) {
      if(request.url.indexOf(this.noTokenApi[i]) >= 0){
        needToken = false;
        break;
      }      
    }
    if (needToken) {
      let token = this.storage.getItem('accessToken');   
      request = this.addToken(request, token || "");
    }
    return next.handle(request)
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (token === "") return request;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  

}
