import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { redirectorUrl } from '../url';

@Component({
  selector: 'order-tracking-redirection',
  templateUrl: './order-tracking-redirection.component.html',
  styleUrl: './order-tracking-redirection.component.scss'
})
export class OrderTrackingRedirectionComponent {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ){
    if (!isPlatformBrowser(this.platformId)) return;

    route.queryParams.subscribe(({c})=>{
      this.redirectToLink(redirectorUrl, c)
    })
  }

  redirectToLink(hostLink: string, orderId: string){
    this.http.get(hostLink + "/" + orderId).subscribe({
      next: (resp: any)=>{
        if(resp && resp.target) {
          this.router.navigateByUrl("/track?"+resp.target.split("?")[1]);
        }
      },
      error: (err)=>{
        this.router.navigate(["/404"]);
      }
    })
  }
}
