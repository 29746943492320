import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, restrictedAuthGuard } from './services/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OrderTrackingRedirectionComponent } from './order-tracking-redirection/order-tracking-redirection.component';

const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import('./login/login.module').then(module => module.LoginModule),
    canActivate: [restrictedAuthGuard],
    data: {
      title: "Login",
      description: "Please login to continue"
    }
  },
  {
    path: "catalog",
    loadChildren: () => import('./catalogue/catalogue.module').then(module => module.CatalogueModule),
    // canActivate: [authGuard]
  },
  {
    path:'track',
    loadChildren:()=> import('./order-tracking/order-tracking.module').then((m)=>m.OrderTrackingModule)
  },
  {
    path: "c/:company/:colId",
    redirectTo: "catalog/:company/:colId",
    pathMatch: "full"
  },
  {
    path: "co/:company/:colId",
    redirectTo: "catalog/:company/:colId/custom",
    pathMatch: "full"
  },

  {
    path: "t",
    component: OrderTrackingRedirectionComponent
  },

  /* this route is deprecated and should be removed */
  {
    path: "custom-order/:company/:colId",
    redirectTo: "catalog/:company/:colId/custom",
    pathMatch: "full"
  },
  /*********************************************** */

  {
    path: "**",
    component: PageNotFoundComponent,
    data: {
      title: "Page not found",
      description: "The page you are looking for does not exist"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
