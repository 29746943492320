import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { redirectorUrl } from '../url';

@Component({
  selector: 'order-tracking-redirection',
  templateUrl: './order-tracking-redirection.component.html',
  styleUrl: './order-tracking-redirection.component.scss'
})
export class OrderTrackingRedirectionComponent {
  hostConfig = {
    local: "localhost",
    testHost: "mrkllp.com",
    prodHost: "saleswz.in",
  }

  redirectionConfig = {
    test: "https://api-slwz.dizital360.com/gate/sales-wizz/ord/n",
    prod: "https://server.saleswizz.com/ord/n"
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ){
    if (!isPlatformBrowser(this.platformId)) return;

    route.params.subscribe(({orderId})=>{
      // if(window.location.hostname === this.hostConfig.testHost){
      //   this.redirectToLink(this.redirectionConfig.test, orderId)
      // } else if(window.location.hostname === this.hostConfig.prodHost){
      //   this.redirectToLink(this.redirectionConfig.prod, orderId)
      // } else if(window.location.hostname === this.hostConfig.local){
      //   this.redirectToLink(this.redirectionConfig.test, orderId)
      // }
      this.redirectToLink(redirectorUrl, orderId)
    })
  }

  redirectToLink(hostLink: string, orderId: string){
    this.http.get(hostLink + "/" + orderId).subscribe({
      next: (resp: any)=>{
        window.location.href = resp.target;
      },
      error: (err)=>{
        this.router.navigate(["/404"]);
      }
    })
  }
}
